<template>
	<div
		v-if="editor"
		class="rich-text-editor"
	>
		<div class="rich-text-editor__menu">
			<button
				class="rich-text-editor__bold"
				:class="{ 'rich-text-editor__bold--active': editor.isActive( 'bold' ) }"
				@click="toggleBold"
			/>
			<button
				class="rich-text-editor__italic"
				:class="{ 'rich-text-editor__italic--active': editor.isActive( 'italic' ) }"
				@click="toggleItalic"
			/>
			<button
				class="rich-text-editor__list"
				:class="{ 'rich-text-editor__list--active': editor.isActive( 'bulletList' ) }"
				@click="toggleList"
			/>
			<button
				class="rich-text-editor__link"
				:class="{ 'rich-text-editor__link--active': editor.isActive( 'link' ) }"
				@click="toggleLink"
			/>
		</div>

		<div
			v-if="timestamp !== null"
			class="rich-text-editor__menu"
		>
			<div class="rich-text-editor__timestamp">
				{{ formatTimestamp( timestamp ) }}
			</div>
			<button
				class="rich-text-editor__remove-timestamp"
				@click="$emit( 'remove-timestamp' )"
			>
				Remove timestamp
			</button>
		</div>

		<EditorContent
			:editor="editor"
			class="rich-text-editor__content"
		/>
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';

export default {
	components: {
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		disabled: {
			require: false,
			type: Boolean,
			default: false
		},
		placeholder: {
			required: false,
			type: String,
			default: 'Leave a comment...'
		},
		timestamp: {
			required: false,
			type: Number,
			default: null
		},
		hotspotData: {
			required: false,
			type: Object,
			default: null
		}
	},
	emits: [ 'update:modelValue', 'remove-timestamp' ],
	data() {
		return {
			editor: null
		};
	},
	watch: {
		modelValue( value ) {
			const is_same = this.editor.getHTML() === value;

			if ( is_same ) {
				return;
			}

			this.editor.commands.setContent( value, false );
		},
		disabled( value ) {
			this.editor.setEditable( !value );
		},
		timestamp( value ) {
			if ( !value ) {
				return;
			}

			this.editor.commands.focus();
		},
		hotspotData( value ) {
			if ( !value ) {
				return;
			}

			this.editor.commands.focus();
		}
	},
	mounted() {
		this.editor = new Editor( {
			extensions: [
				StarterKit, // eslint-disable-line snakecasejs/snakecasejs
				Placeholder.configure( {
					placeholder: this.placeholder
				} ),
				Link.configure( {
					HTMLAttributes: {
						target: null
					},
				} )
			],
			content: this.modelValue,
			onUpdate: () => {
				this.$emit( 'update:modelValue', this.editor.getHTML() );
			},
		} );
	},
	beforeUnmount() {
		this.editor.destroy();
		this.$emit( 'remove-timestamp' );
	},
	methods: {
		toggleBold() {
			this.editor.chain().focus().toggleBold().run();
		},
		toggleItalic() {
			this.editor.chain().focus().toggleItalic().run();
		},
		toggleList() {
			this.editor.chain().focus().toggleBulletList().run();
		},
		toggleLink() {
			if ( this.editor.isActive( 'link' ) ) {
				this.editor.chain().focus().unsetLink().run();
				return;
			}
			this.setLink();
		},
		setLink() {
			// Source: https://tiptap.dev/api/marks/link#usage
			const previous_url = this.editor.getAttributes( 'link' ).href;
			const url = window.prompt( 'URL', previous_url );

			// cancelled
			if ( url === null ) {
				return;
			}

			// empty
			if ( url === '' ) {
				this.editor
					.chain()
					.focus()
					.extendMarkRange( 'link' )
					.unsetLink()
					.run()
				;

				return;
			}

			// update link
			const is_external_url = new URL( url ).origin !== location.origin;
			const link = { href: url, target: is_external_url ? '_blank' : null };

			this.editor
				.chain()
				.focus()
				.extendMarkRange( 'link' )
				.setLink( link )
				.run()
			;
		},
		formatTimestamp( timestamp_in_seconds ) {
			const mins = Math.floor( timestamp_in_seconds / 60 );
			const secs = Math.floor( timestamp_in_seconds % 60 );

			return secs < 10 ? `${mins}:0${secs}` : `${mins}:${secs}`;
		}
	}
};
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "comment-form__errors"
}
const _hoisted_2 = {
  key: 1,
  class: "comment-form__submit-container"
}
const _hoisted_3 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RichTextEditorComponent = _resolveComponent("RichTextEditorComponent")
  const _component_CommentFileUploadComponent = _resolveComponent("CommentFileUploadComponent")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["comment-form", [
			{ 'comment-form--alt': $props.commentType === 'discussion' },
			{ 'comment-form--no-border': $props.noBorder }
		]])
  }, [
    _createVNode(_component_RichTextEditorComponent, {
      modelValue: $data.content,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.content) = $event)),
      disabled: $data.loading,
      placeholder: $props.commentType === 'discussion' ? 'Start writing your comment here...' : 'Leave a comment...',
      timestamp: $setup.comment_timestamp,
      "hotspot-data": $setup.hotspot_data,
      onRemoveTimestamp: _cache[1] || (_cache[1] = $event => ($setup.updateCommentTimestamp( null )))
    }, null, 8 /* PROPS */, ["modelValue", "disabled", "placeholder", "timestamp", "hotspot-data"]),
    _createVNode(_component_CommentFileUploadComponent, {
      ref: "image-upload",
      "comment-id": $data.comment_id,
      onFilesAdded: $options.updateFileStatus,
      onFilesRemoved: $options.updateFileStatus,
      onUploadCompleted: $options.finishCommentSubmission,
      onUploadFailed: _cache[2] || (_cache[2] = $event => ($options.finishCommentSubmission( false )))
    }, null, 8 /* PROPS */, ["comment-id", "onFilesAdded", "onFilesRemoved", "onUploadCompleted"]),
    ($data.errors.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: 'error-' + index,
              class: "comment-form__error"
            }, _toDisplayString(error), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$props.hideButtons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: _normalizeClass(["comment-form__submit", { 'comment-form__submit--loading': $data.loading }]),
            disabled: $data.loading,
            type: "submit",
            onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.submitComment && $options.submitComment(...args)), ["prevent"]))
          }, _toDisplayString($data.loading ? 'Posting' : 'Post'), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}